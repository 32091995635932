interface IntegrationPresentData {
  type: 'present';
  seriesId: string;
  questionId: string;
  voteKey: string;
}

interface IntegrationOpenUrlData {
  type: 'open-url';
  payload: string;
}

export type IntegrationMessageEvent =
  | IntegrationPresentData
  | IntegrationOpenUrlData;

export const postIntegrationMessage = (
  parent: Window | undefined,
  data: IntegrationMessageEvent,
  origin: string,
) => {
  if (!parent) return;
  parent.postMessage(data, origin);
};
