/* eslint-disable menti-react/filename-convention--jsx */
import type { SVGAttributes } from 'react';
import React from 'react';

interface SvgT extends SVGAttributes<SVGSVGElement> {
  children: React.ReactNode;
  'aria-label'?: string | undefined;
}

/**
 * This component is a copy from the Svg component in @mentimeter/ragnar-ui.
 * The reason we can't import it from there is that it create circular package
 * references between @mentimeter/ragnar-visuals and @mentimeter/ragnar-ui.
 */
export const Svg = ({ children, 'aria-label': ariaLabel, ...rest }: SvgT) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      preserveAspectRatio="xMidYMid meet"
      width="100%"
      height="100%"
      aria-label={ariaLabel}
      {...rest}
    >
      {children}
    </svg>
  );
};
