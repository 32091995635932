import * as React from 'react';
import { isThemeColor } from '@mentimeter/ragnar-dsc';
import { useRagnar } from '@mentimeter/ragnar-react';
import { Svg } from '../shared-components';
import { type FunctionalIconWrapperT } from './FunctionalIcon.types';

export const FunctionalIconWrapper = ({
  size = 2,
  color = 'text',
  children,
  viewBox = '0 0 16 16',
  'aria-hidden': ariaHidden,
  'aria-label': ariaLabel,
}: FunctionalIconWrapperT) => {
  const { theme } = useRagnar();
  const fillColor = color === 'inherit' ? 'currentColor' : color;
  return (
    <Svg
      viewBox={viewBox}
      height={typeof size === 'number' ? theme.fontSizes[size] || size : size}
      width={typeof size === 'number' ? theme.fontSizes[size] || size : size}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
    >
      <g
        fill={
          isThemeColor(theme, fillColor) ? theme.colors[fillColor] : fillColor
        }
        fillRule="evenodd"
        clipRule="evenodd"
      >
        {children}
      </g>
    </Svg>
  );
};
