import * as React from 'react';
import {
  CatReaction,
  HeartReaction,
  QuestionReaction,
  ThumbsDownReaction,
  ThumbsUpReaction,
} from '@mentimeter/ragnar-visuals';
import type { Reaction } from '@mentimeter/http-clients';

/*
 *   Helpers to reactions
 */
const ICON_SIZE = 4;
// WE NEED A CAT AND QUESTION ICON!
export const REACTION_MAP: Record<Reaction, (c: string) => React.ReactNode> = {
  heart1: (c) => <HeartReaction color={c} size={ICON_SIZE} />,
  thumbsup: (c) => <ThumbsUpReaction color={c} size={ICON_SIZE} />,
  thumbsdown: (c) => <ThumbsDownReaction color={c} size={ICON_SIZE} />,
  question: (c) => <QuestionReaction color={c} size={ICON_SIZE} />,
  cat: (c) => <CatReaction color={c} size={ICON_SIZE} />,
};

export const REACTION_SORT_MAP: Record<Reaction, number> = {
  heart1: 1,
  question: 2,
  thumbsup: 3,
  thumbsdown: 4,
  cat: 5,
};

export const getReaction = (
  reaction: Reaction,
  color: string,
): React.ReactNode | null => {
  const fn = REACTION_MAP[reaction];
  if (fn) {
    return fn(color);
  }
  return null;
};
