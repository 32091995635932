import * as React from 'react';
import { Button, type ButtonT } from '@mentimeter/ragnar-ui';
import { ChevronRightIcon } from '@mentimeter/ragnar-visuals';

export interface NavigationButtonProps extends ButtonT {
  onClick: React.MouseEventHandler<Element>;
}

export const NavigationButton = ({
  children,
  onClick,
}: NavigationButtonProps) => (
  <Button
    size="large"
    iconTrailing={ChevronRightIcon}
    variant="subtle"
    mt="space4"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
);
