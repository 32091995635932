import * as React from 'react';
import { animated, useTransition } from '@react-spring/web';
import type { ThemeProps } from '@mentimeter/ragnar-react';
import { withTheme } from '@mentimeter/ragnar-react';
import { Box } from '@mentimeter/ragnar-ui';
import type { Reaction as ReactionType } from '@mentimeter/http-clients';
import { getReaction } from './Utils';

const REACTION_Y_OFFSET = 70;

type Props = ThemeProps & {
  enable?: boolean;
  reaction: ReactionType;
  children: React.ReactNode;
  themeIndex: number;
  vertical?: boolean;
};

const ReactionAnimation = ({
  reaction,
  children,
  themeIndex,
  theme,
  vertical = true,
  enable = true,
}: Props) => {
  const [currentReactions, setReactions] = React.useState<Date[]>([]);

  const handleClick = () => {
    if (enable) {
      const timestamp = new Date();
      setReactions([...currentReactions, timestamp]);
      setTimeout(
        () => setReactions((state) => state.filter((r) => r !== timestamp)),
        400,
      );
    }
  };

  const transitions = useTransition(currentReactions, {
    from: { transform: 0, opacity: 0 },
    enter: { transform: 1, opacity: 1 },
    leave: { transform: 2, opacity: 0 },

    config: {
      tension: 180,
      friction: 12,
    },
  });

  return (
    <Box onClick={handleClick}>
      {transitions(({ transform, opacity }) => (
        <animated.div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none',
            zIndex: 2,
            position: 'absolute',
            width: '100%',
            height: '100%',
            transform: transform.to(
              (t) =>
                `translate3d(-${!vertical ? t * REACTION_Y_OFFSET : 0}px,-${
                  vertical ? t * REACTION_Y_OFFSET : 0
                }px,0) scale(${0.8 + t})`,
            ),
            opacity,
          }}
        >
          {getReaction(
            reaction,
            theme.visualizationColors.fillColors[
              themeIndex % theme.visualizationColors.fillColors.length
            ]!,
          )}
        </animated.div>
      ))}
      {children}
    </Box>
  );
};
export default withTheme(ReactionAnimation);
